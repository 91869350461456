
import RootVue from '@/RootVue.vue';
import { Vue, Options } from 'vue-class-component';
import DialogFrame from '@/components/dialogs/DialogFrame.vue'
import SelectPrefecture from '@/components/parts/SelectPrefecture.vue'
import CustomerRes from '@/ts/interface/CustomerRes';
import CustomerTableRes from '@/ts/interface/CustomerTableRes';
import Pager from "@/components/parts/Pager.vue";
import SearchReq from '@/ts/interface/SearchReq';
import { Ref } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';
import api from '@/ts/ajax/api';
import {PAGE_SIZE} from '@/ts/const';

@Options({
  components: {
    DialogFrame,
    SelectPrefecture,
    Pager,
    Loading
  }
})
export default class extends Vue {
  // @ts-expect-error
  $root: RootVue;
  
  //mounted前のlifesycleだと初期化前で使用できない点に注意
  @Ref() readonly loading = null as any as Loading;
  
  isDisplay = false;

  resolve:Function|null = null;
  reject:Function|null = null;


  searchReq = {} as SearchReq;
  customerTable = {}  as CustomerTableRes

  //親から呼び出し用
  public async openDialog(initialId:string|number = "", excludeId?:string|number):Promise<CustomerRes|null>{
    this.initList()
    this.searchReq.id = initialId
    this.searchReq.excludeId = excludeId?.toString();
    this.isDisplay = true;
    this.load()
    
    return new Promise((resolve, reject)=>{
      this.resolve = resolve
      this.reject = reject
    });
  }

  //親から呼び出し用
  public closeDialog(){
    this.isDisplay = false;
    if(this.resolve) this.resolve(null)
    this.resolve = null
    this.reject = null
  }

  search(clearIndex=true){
    //enterでsearchを起動した場合、changeに登録した全角->半角変換処理より早くsearchが動作するため変換を先に行う
    this.toHankakuCode()
    this.toHankanaWhole()
    this.toHankakuPhone()
    if(clearIndex) this.searchReq.startIndex = 0
    this.load()
  }
  
  changePage(pageIndex:number) {
    this.searchReq.startIndex = pageIndex * this.searchReq.length!
    this.search(false)
  }

  load(){
    this.loading?.start()
    api.getCustomerTable(this.searchReq).then((resp:Response)=>{
      if(resp.ok){
        resp.json().then((data:CustomerTableRes) => {
          this.customerTable = data
          this.loading?.finished()
        })
      }
    })
  }

  select(customer:CustomerRes){
    this.isDisplay = false;
    if(this.resolve) this.resolve(customer)
    this.resolve = null
    this.reject = null
  }
  
  toHankakuCode(){
    this.searchReq.id = this.$root.util.hankaku2Zenkaku(this.searchReq.id)
  }

  toHankanaWhole(){
    this.searchReq.name = this.$root.util.toHankanaWhole(this.searchReq.name)
  }
  
  toHankakuPhone(){
    this.searchReq.phoneNo = this.$root.util.hankaku2Zenkaku(this.searchReq.phoneNo)
  }

  initList(){
    this.customerTable = {list:[]} as any as CustomerTableRes
    this.searchReq = {} as any
    this.searchReq.startIndex = 0
    this.searchReq.length = PAGE_SIZE
  }

  created(){
    this.initList()
  }
}
