
import RootVue from '@/RootVue.vue';
import api from '@/ts/ajax/api';
import StoreMaster from '@/ts/interface/StoreMaster';
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    store:{
      type: [Number, String],
    },
  },
  components: {
  }
})
export default class extends Vue {
  // @ts-expect-error
  $root: RootVue;

  store!: String;
  list = [] as StoreMaster[];
  isLoaded = false

  onChange($event:any){
    this.$emit('update:store', $event.target.value)
  }
  created(){
    api.getStore().then((res:Response)=>{
      if(res.ok){
        res.json().then((data:StoreMaster[])=>{
          this.list = data
          this.isLoaded = true
        })
      }
    })
  }
}
