import { createWebHistory, createRouter, RouteLocationNormalized,  NavigationGuardNext } from "vue-router";
import LoginPage from '@/page/LoginPage.vue'
import MenuPage from '@/page/MenuPage.vue'
import ActionHistoryPage from '@/page/ActionHistoryPage.vue'
import OrderHistoryPage from '@/page/OrderHistoryPage.vue'

import ActionMasterPage from '@/page/master/ActionMasterPage.vue'
import NoshigamiMasterPage from '@/page/master/NoshigamiMasterPage.vue'
import StoreMasterPage from '@/page/master/StoreMasterPage.vue'
import CustomerMasterPage from '@/page/master/CustomerMasterPage.vue'

import NotFoundPage from '@/page/NotFoundPage.vue'
import api from "./ts/ajax/api";
import globalState from "./ts/state/globalState";
import LoginRes from "./ts/interface/LoginRes";

const routes = [
  {
    path: '/',
    meta: {
      title: "顧客管理システム",
      noBackLink: true,
      noAuthentication: true,
    },
    component: LoginPage,
  },
  {
    path: '/menu',
    meta: {
      title: "メニュー",
      noBackLink: true
    },
    component: MenuPage,
  },
  {
    path: '/action',
    meta: {
      title: "対応"
    },
    component: ActionHistoryPage,
  },
  {
    path: '/order',
    meta: {
      title: "注文"
    },
    component: OrderHistoryPage,
  },
  {
    path: '/master/action',
    meta: {
      title: "対応種類"
    },
    component: ActionMasterPage,
  },
  {
    path: '/master/noshigami',
    meta: {
      title: "のし紙"
    },
    component: NoshigamiMasterPage,
  },
  {
    path: '/master/store',
    meta: {
      title: "担当店"
    },
    component: StoreMasterPage,
  },
  {
    path: '/master/customer',
    meta: {
      title: "顧客"
    },
    component: CustomerMasterPage,
  },



  //上から順にマッチした画面が表示されるので、一番最後に定義する
  {
    path: '/:pathMatch(.*)*',
    meta: {
      title: "not found",
      noAuthentication: true,
    },
    component: NotFoundPage,
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to:RouteLocationNormalized, from:RouteLocationNormalized,  next:NavigationGuardNext) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title as any;
  }

  if (globalState.isLogined() || (to.meta && to.meta.noAuthentication)) {
    globalState.editingLock.leave()
    next();
  //ログイン前、かつ、ログインが必要なページの場合
  }else{
    api.getAccount().then((resp:Response)=>{
      if(resp.ok){
        resp.json().then((data:LoginRes)=>{
          globalState.setLoginInfo(data)
          next();
        })
      }else{
        console.log("ログイン情報がなかったためログイン画面に遷移します。")
        router.push("/");
      }
    }).catch(()=>{
    })
  }
})

window.onbeforeunload = () => {
  globalState.editingLock.leave()
};


//このオブジェクトは、.vueファイル中のthis.$router === routerとなる。（main.tsでRootVueに含めるため）
export default router;