
import RootVue from "@/RootVue.vue";
import { Vue, Options } from "vue-class-component";

const BUTTON_COUNT = 5

@Options({
  props: {
    itemIndex: {
      type: [Number, String],
    },
    itemLength: {
      type: [Number, String],
    },
    itemsInPage: {
      type: [Number, String],
    },
  },
  emits: ['click-page'],
  components: {},
})
export default class extends Vue {
  // @ts-expect-error
  $root: RootVue;
  
  itemIndex!: number;
  itemLength!: number;
  itemsInPage!: number;

  get pageIndex(){
    if(
         Number.isInteger(Number(this.itemIndex))
      && Number.isInteger(Number(this.itemsInPage))
    ){
      return Math.floor(this.itemIndex / this.itemsInPage)
    }else {
      return 0
    }
  }
  get pageLength(){
    if(
         Number.isInteger(Number(this.itemLength))
      && Number.isInteger(Number(this.itemsInPage))
    ){
      return Math.ceil(this.itemLength / this.itemsInPage)
    }else {
      return 0
    }
  }

  get beforeDisabled() {
    return this.beforeTabIndex === 0 ? "" : "disabled"
  }

  get afterDisabled() {
    return this.afterTabIndex === 0 ? "" : "disabled"
  }

  get beforeTabIndex() {
    return (this.pageLength > 0 && this.pageIndex >= 1) ? 0 : -1;
  }
  get afterTabIndex() {
    return (this.pageLength > 0 && this.pageIndex + 1 < this.pageLength) ? 0 : -1;
  }

  get buttonBeforeCount () {
    return Math.min(Math.max(this.pageIndex, 0), BUTTON_COUNT) 
  }
  get buttonAfterCount () {
    return Math.min(Math.max(this.pageLength - this.pageIndex - 1, 0), BUTTON_COUNT) 
  }

  get buttonBeforeStart () {
    return Math.max(this.pageIndex - BUTTON_COUNT, 0) 
  }
  get buttonAfterStart () {
    return this.pageIndex + 1
  }

}
