import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-629772a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding":"30px 20px","width":"60vw","min-width":"300px","text-align":"center","position":"relative"} }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialog_frame = _resolveComponent("dialog-frame")!

  return (_ctx.isDisplay)
    ? (_openBlock(), _createBlock(_component_dialog_frame, {
        key: 0,
        onClickBackground: _ctx.closeDialog
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "close-button-position-absolute",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeDialog && _ctx.closeDialog(...args)))
            }, "x"),
            _createElementVNode("h3", { innerHTML: _ctx.message }, null, 8, _hoisted_2),
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                class: "btn-primary",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.doDelete && _ctx.doDelete(...args)))
              }, _toDisplayString(_ctx.yesButton), 1),
              _createElementVNode("button", {
                id: "cancelDelete",
                class: "btn-outline-primary",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeDialog && _ctx.closeDialog(...args)))
              }, _toDisplayString(_ctx.noButton), 1)
            ])
          ])
        ]),
        _: 1
      }, 8, ["onClickBackground"]))
    : _createCommentVNode("", true)
}