import api from "../ajax/api"
import globalState from "./globalState"

export default class {
    private targetCustomer: number|null = null

    public enter(targetCustomerId:number){
        this.targetCustomer = targetCustomerId
        api.setCustomerEditing(targetCustomerId)
    }
    
    public leave(){
        if(this.targetCustomer){    
            api.clearCustomerEditing(this.targetCustomer)
            this.targetCustomer = null
        }
        globalState.nortification.clear("customer-edit-warning")
    }

    public showLockMsg(){
        globalState.nortification.add({identifer:"customer-edit-warning", type:"warn", message:"他のユーザが編集中です"})
    }
}