import CustomerRes from "@/ts/interface/CustomerRes"
import OrderDestinationHistory from "@/ts/interface/OrderDestinationHistory"

export const nullToEmpty = (val:any) =>{
    if(val === null || val === undefined){
        return ""
    }else{
        return val
    }
}
export const deepCopy = <T>(obj:T) :T =>{
    //関数はコピーされないので注意
    return JSON.parse(JSON.stringify(obj))
}

export const getAddress = (customer: CustomerRes) => {
    return nullToEmpty(customer.prefecture)
     + nullToEmpty(customer.city)
     + nullToEmpty(customer.street)
     + nullToEmpty(customer.building);
}
export const getOrderDestAddress = (order: OrderDestinationHistory) => {
    return nullToEmpty(order.customerPrefecture)
     + nullToEmpty(order.customerCity)
     + nullToEmpty(order.customerStreet)
     + nullToEmpty(order.customerBuilding);
}
export const formatDate = (dateInputValue: any) => {
    if(dateInputValue){
        //toISOStringは「yyyy-mm-ddTHH:MM:ss.sssZ」形式のUTC時刻の文字列になる
        return getTimezoneOffsetDate(dateInputValue).toISOString().split('T')[0].replace(/-/g, '/')
    }else{
        return ""
    }
}
export const formatDateTime = (dateInputValue: any) => {
    if(dateInputValue){
        //toISOStringは「yyyy-mm-ddTHH:MM:ss.sssZ」形式のUTC時刻の文字列になる
        return getTimezoneOffsetDate(dateInputValue).toISOString().replace('T', ' ').replace(/-/g, '/').replace(/\.\d+[Zz]$/, '');
    }else{
        return ""
    }
}

export const formatDateTimeToInput = (dateDBValue: any) => {
    if(dateDBValue){
        //toISOStringは「yyyy-mm-ddTHH:MM:ss.sssZ」形式のUTC時刻の文字列になる
        return getTimezoneOffsetDate(dateDBValue).toISOString().replace(/[Zz]$/, '');
    }else{
        return ""
    }
}
export const formatDateToInput = (dateDBValue: any) => {
    if(dateDBValue){
        //toISOStringは「yyyy-mm-ddTHH:MM:ss.sssZ」形式のUTC時刻の文字列になる
        return getTimezoneOffsetDate(dateDBValue).toISOString().replace(/[T].*$/, '');
    }else{
        return ""
    }
}

const getTimezoneOffsetDate = (dateInputValue: any) => {
    const date = new Date(dateInputValue)
    const offset = date.getTimezoneOffset()
    return new Date(date.getTime() - (offset*60*1000))
}

export const createQueryString = (obj:any) => {
    //undefinedが設定されたpropertyを削除する
    Object.keys(obj).forEach(key => {
        if(obj[key] === undefined) {
            delete obj[key] 
        }
    });

    const urlParams = new URLSearchParams(obj);
    return urlParams.toString()
}

export const hankaku2Zenkaku = <T>(str:T) => {
    if(!str) return str;

    let ret = (str as any).replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s:any) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    ret = ret.replace(/[ー－]/ig, "-")
    return ret as T
}

export const removeEmptyProperties = (obj:any) => {
    Object.keys(obj).forEach(k =>{
        if(obj[k] === null || obj[k] === ""){
            delete obj[k]
        }
    })
    return obj;
}

export const download = (blob:Blob, fileName:string) => {
    const link=document.createElement('a');
    link.href=window.URL.createObjectURL(blob);
    link.download= fileName;
    link.click();
}

//すべて半角カナ変換する。１文字でも半角カナ変換できない文字があれば、すべて半角カナ変換しない
export const toHankanaWhole = (str:string|undefined) => {
    const replaced = zenkana2Hankana(hiraToKana(str))
    if(isAllHankana(replaced)){
        return replaced
    }else{
        return str
    }
}

export const toHankana = (str:string|undefined) => {
    return zenkana2Hankana(hiraToKana(str))
}

export const hiraToKana = (str:string|undefined) => {
    if(str === null || str === undefined){
        return ""
    }
    return str.replace(/[\u3041-\u3096]/g, function(match) {
        const chr = match.charCodeAt(0) + 0x60;
        return String.fromCharCode(chr);
    });
}

const zenkakuMap = {
    "ガ": "ｶﾞ", "ギ": "ｷﾞ", "グ": "ｸﾞ", "ゲ": "ｹﾞ", "ゴ": "ｺﾞ",
    "ザ": "ｻﾞ", "ジ": "ｼﾞ", "ズ": "ｽﾞ", "ゼ": "ｾﾞ", "ゾ": "ｿﾞ",
    "ダ": "ﾀﾞ", "ヂ": "ﾁﾞ", "ヅ": "ﾂﾞ", "デ": "ﾃﾞ", "ド": "ﾄﾞ",
    "バ": "ﾊﾞ", "ビ": "ﾋﾞ", "ブ": "ﾌﾞ", "ベ": "ﾍﾞ", "ボ": "ﾎﾞ",
    "パ": "ﾊﾟ", "ピ": "ﾋﾟ", "プ": "ﾌﾟ", "ペ": "ﾍﾟ", "ポ": "ﾎﾟ",
    "ヴ": "ｳﾞ", "ヷ": "ﾜﾞ", "ヺ": "ｦﾞ",
    "ア": "ｱ", "イ": "ｲ", "ウ": "ｳ", "エ": "ｴ", "オ": "ｵ",
    "カ": "ｶ", "キ": "ｷ", "ク": "ｸ", "ケ": "ｹ", "コ": "ｺ",
    "サ": "ｻ", "シ": "ｼ", "ス": "ｽ", "セ": "ｾ", "ソ": "ｿ",
    "タ": "ﾀ", "チ": "ﾁ", "ツ": "ﾂ", "テ": "ﾃ", "ト": "ﾄ",
    "ナ": "ﾅ", "ニ": "ﾆ", "ヌ": "ﾇ", "ネ": "ﾈ", "ノ": "ﾉ",
    "ハ": "ﾊ", "ヒ": "ﾋ", "フ": "ﾌ", "ヘ": "ﾍ", "ホ": "ﾎ",
    "マ": "ﾏ", "ミ": "ﾐ", "ム": "ﾑ", "メ": "ﾒ", "モ": "ﾓ",
    "ヤ": "ﾔ", "ユ": "ﾕ", "ヨ": "ﾖ",
    "ラ": "ﾗ", "リ": "ﾘ", "ル": "ﾙ", "レ": "ﾚ", "ロ": "ﾛ",
    "ワ": "ﾜ", "ヲ": "ｦ", "ン": "ﾝ",
    "ァ": "ｧ", "ィ": "ｨ", "ゥ": "ｩ", "ェ": "ｪ", "ォ": "ｫ",
    "ッ": "ｯ", "ャ": "ｬ", "ュ": "ｭ", "ョ": "ｮ",
    "。": "｡", "、": "､", "ー": "ｰ", "「": "｢", "」": "｣", "・": "･", "゛":'ﾞ', "゜":'ﾟ',
    "　":" ", "  ":" ",
} as any;

export const zenkana2Hankana = (str:string|undefined) => {
    if(str === null || str === undefined){
        return ""
    }

    const reg = new RegExp('(' + Object.keys(zenkakuMap).join('|') + ')', 'g');
    return str
            .replace(reg, function (match) {
                return zenkakuMap[match];
            });
};

export const isAllHankana = (str:string|undefined) => {
    if(str === null || str === undefined){
        return false
    }

    const reg = new RegExp('[^' + Object.values(zenkakuMap).join('') + ']', 'g');
    return !reg.test(str)
};



export const getTodayString = () => {
    const date = new Date()
    date.setHours(date.getHours() + 9)
    return date.toISOString().replace(/T.+$/, "") 
}

export const getNowString = () => {
    const date = new Date()
    date.setHours(date.getHours() + 9)
    return date.toISOString().replace(/\..+$/, "").replace("T", "-").replace(/:/gi, "-")
}

export const getStartString = (str:string|number, len:number) => {
    if(str){
        return str.toString().substr(0, len)
    } else{
        return str
    }

}

export const isValidDate = (date:string) => {
    const d = new Date(date)
    return !date || d instanceof Date && !isNaN(d as any) && d.getFullYear() <= 9999
}

//たぶん使わないほうがいいだろう
// export const toCode = (id:number) => {
//     return id.toString().padStart(5, "0")
// }


//idを持つ任意の型
type ObjctHasId = {id:number, [x: string]: any }
export const updateOrPushById = <T extends ObjctHasId>(list:T[], item:T) => {
    const index = list.findIndex(i => i.id === item.id)
    if(index >= 0){
      list[index] = item
    }else{
      list.push(item)
    }
}