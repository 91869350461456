
import RootVue from '@/RootVue.vue';
import { Vue, Options } from 'vue-class-component';
import DialogFrame from '@/components/dialogs/DialogFrame.vue'
import SelectPrefecture from '@/components/parts/SelectPrefecture.vue'
import CustomerRes from '@/ts/interface/CustomerRes';
import Pager from "@/components/parts/Pager.vue";
@Options({
  components: {
    DialogFrame,
    SelectPrefecture,
    Pager
  }
})
export default class extends Vue {
  // @ts-expect-error
  $root: RootVue;
  isDisplay = false;
  
  message = ""
  yesButton = ""
  noButton = ""

  resolve:Function|null = null;
  reject:Function|null = null;

  //親から呼び出し用
  public async openDialog(message:string, yesButton:string, noButton = "キャンセル"):Promise<CustomerRes|null>{
    this.isDisplay = true;
    this.message = message
    this.yesButton = yesButton
    this.noButton = noButton
    
    this.$root.focus("cancelDelete")
    return new Promise((resolve, reject)=>{
      this.resolve = resolve
      this.reject = reject
    });
  }

  //親から呼び出し用
  public closeDialog(){
    this.isDisplay = false;
    if(this.resolve) this.resolve(false)
    this.resolve = null
    this.reject = null
  }

  doDelete(){
    this.isDisplay = false;
    if(this.resolve) this.resolve(true)
    this.resolve = null
    this.reject = null
  }
}
