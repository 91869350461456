
export default class Address {
  host: string;

  constructor(host: string) {
    this.host = host;
  }

  loginPath = () => {
    return this.host + "login";
  }

  loginAccountPath = () => {
    return this.host + "account";
  }

  addressPath = (zipCode:string|number) => {
    return this.host + "address/" + zipCode;
  }

  actionPath = (id?:number) => {
    return this.host + "action" + (id ? ("/" + id) :"");
  }

  noshigamiPath = (id?:number) => {
    return this.host + "noshigami" + (id ? ("/" + id) :"");
  }
  
  storePath = (id?:number) => {
    return this.host + "store" + (id ? ("/" + id) :"");
  }

  customerTablePath = () => {
    return this.host + "customertable";
  }

  customerPath = (id?:number) => {
    return this.host + "customer" + (id ? ("/" + id) :"");
  }

  customerDeletePath = () => {
    return this.host + "customerdelete";
  }

  customerDestinationPath = (customerId:string|number, destId?:string|number) => {
    return this.host + "customerdestination/" + customerId + (destId ? ("/" + destId)  : "");
  }
  
  getCustomerCSVPath = () => {
    return this.host + "customercsv";
  }

  postCustomerCSVPath = (forceUpload:boolean) => {
    return this.host + "customercsv" + (forceUpload ? "/force": "");
  }

  customerEditingPath = (customerId:string|number) => {
    return this.host + "editinguser/" + customerId;
  }

  uketamawarishoPath = () => {
    return this.host + "print/uketamawarisho";
  }

  labelPath = () => {
    return this.host + "print/label";
  }

  yamatoCSVPath = () => {
    return this.host + "yamatocsv";
  }

  actionHistoryTablePath = () => {
    return this.host + "actionhistorytable";
  }

  actionHistoryPath = (id?:number) => {
    return this.host + "actionhistory" + (id ? ("/" + id) :"");
  }

  orderHistoryTablePath = () => {
    return this.host + "orderhistorytable";
  }  

  orderDestinationPath = (orderId:string|number, destId?:string|number) => {
    return this.host + "orderdestinationhistory/" + orderId + (destId ? ("/" + destId)  : "");
  }

  postOrderHistoryPath = () => {
    return this.host + "postOrderHistory";
  }
  putOrderHistoryPath = (orderId:string|number) => {
    return this.host + "putOrderHistory/" + orderId;
  }

  deleteOrderHistoryPath = (id:number) => {
    return this.host + "orderhistory/" + id;
  }
   
}
