
import "@/ts/onlyVueCli/initWindow";

import '@/css/common.scss'
import '@/css/buttons.scss'
import '@/css/input.scss'
import '@/css/table.scss'
import '@/css/custom.scss'

import { createApp } from 'vue'
import RootVue from './RootVue.vue'
import router from './router'
import JapaneseInput from '@/components/parts/JapaneseInput.vue'
import JapaneseInputXS from '@/components/parts/JapaneseInput-xs.vue'

const app = createApp(RootVue)
app.component('JapaneseInput', JapaneseInput)
app.component('JapaneseInput-xs', JapaneseInputXS)


app.use(router).mount('#app')
