import router from "@/router";
import globalState from "../state/globalState";

export default class Rest {

  get = (url: string, queryString?: string): Promise<Response> => {
    return this.requestAPI(url, queryString, this.getSetting());
  }

  post = (url: string, queryString?: string, body?: any, isJSON=true): Promise<Response> => {
    return this.requestAPI(url, queryString, this.postSetting("POST", body, isJSON));
  }

  put = (url: string, queryString?: string, body?: any, isJSON=true): Promise<Response> => {
    return this.requestAPI(url, queryString, this.postSetting("PUT", body, isJSON));
  }

  delete = (url: string, queryString?: string, body?: any): Promise<Response> => {
    return this.requestAPI(url, queryString, this.postSetting("DELETE", body, true));
  }

  private getSetting = () => {
    return { 
      method: "GET", 
      headers: this.createGetHeader() 
    } as RequestInit;
  }

  // post設定
  private postSetting = (method:string, body: any, isJSON:boolean) => {
    return { 
      method, 
      headers: this.createPostHeader(), 
      body: isJSON ? JSON.stringify(body) : body 
    } as RequestInit;
  }

  private createGetHeader = () => {
    return this.addTokens({
      "Accept": "application/json",
    });
  }
  
  private createPostHeader = (type = "application/json", _length = 0) => {
    return this.addTokens({
      "Accept": "application/json",
      "Content-type": type,
      // "Content-Length": length, //サロゲートペア問題とかもあるので、一旦放置。
    });
  }

  private addTokens(headers: any) {
    if(globalState.getLoginInfo().csrftoken){
      headers["X-CSRFToken"] = globalState.getLoginInfo().csrftoken
    }
    return headers;
  }

  private addCredentials(init: RequestInit) {
    //Session情報を含める。Vue-cliで動作させる時は、CROS（Spring boot）サーバーにSession情報を送るように"include"にする。
    init["credentials"] = process.env.VUE_APP_MODE === "development" ? "include" : "same-origin";
    return init
  }

  private requestAPI = (url: string, queryString: string|undefined, init: RequestInit, tryCount = 0): Promise<Response> => {
    init = this.addCredentials(init);
    const ret = fetch(queryString ? url + "?" + queryString : url, init)
      // console.log("requestAPI url:", url, " queryString:", queryString, " init:", init, " tryCount:", tryCount);
      //レスポンスがない場合のエラーだけを処理したいので、thenより先にcatchを追加する。（thenを先に持ってくるとそのthenで発生した例外はその後のcatchの対象になる）
      .catch((error: any) => {
        if (process.env.VUE_APP_MODE === "development") {
          console.debug("通信エラー(%d)。サーバーが起動しているか確認してください。", tryCount, error);
        }

        return new Promise<any>((resolve, reject) => {
          if(tryCount < 3){
            setTimeout(() => {
              resolve(this.requestAPI(url, queryString, init, ++tryCount));
            }, tryCount * tryCount * 300 + 500)
          }else{
            console.error("通信エラーが発生しました。", error);
            reject(error)
          }
        });
      });

    if (tryCount === 0) {
      return ret.then(this.whenHasResponse);
    } else {
      return ret;
    }
  }

  private whenHasResponse = (response: Response): Response => {
    if (response.ok) {
      // console.log("fetch response:", response);
    } else {
      if(response.status === 401 || response.status === 403){
        if(!router.currentRoute.value.meta.noAuthentication){
          globalState.nortification.add({identifer:"rest-error", type:"error", message:"ログインが切れました。ログイン画面より再ログインしてください。"})
        }
      }
      if (process.env.VUE_APP_MODE === "development") {
        if (response.status >= 500) {
          console.debug("想定外のエラー。URLが間違ってないか確認してください。", response);
        }
      }
    }
    return response;
  }
}