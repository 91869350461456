
import RootVue from "@/RootVue.vue";
import { Options, Vue } from 'vue-class-component';


@Options({
  props: {
    display:{
      type: String,
      default: "block",
    },
    minHeight:{
      type: String,
      default: "",
    },
    minWidth:{
      type: String,
      default: "",
    },
    imgHeight:{
      type: String,
      default: "36px",
    },
    imgWidth:{
      type: String,
      default: "36px",
    },
    initialLoad:{
      type: Boolean,
      default: true,
    },
    enableOnScrollEnd:{
      default:false,
      type: Boolean,
    },
    scrollEndMargin:{
      default:100,
      type: Number,
    }
  },
  components: {
  }
})
export default class extends Vue {
  // @ts-expect-error
  $root: RootVue;
  
  private minHeight!: string;
  private minWidth!: string;
  private initialLoad!: boolean;
  private enableOnScrollEnd!:boolean;
  private scrollEndMargin!:number;
  private isScrollEnd = false;

  private nowLoading = true;
  private showContentsOnLoading = false;
  public isFirstLoadFinished = false;

  public start(showContentsOnLoading = false){
    this.nowLoading = true
    this.showContentsOnLoading = showContentsOnLoading
  }
  public finished(){
    this.nowLoading = false
    this.isFirstLoadFinished = true
  }
  public enableNextScrollEnd(){
    this.isScrollEnd = false
    //画面更新後に判定を行う
    this.$nextTick(()=>{
      this.onScroll()
    })
  }

  onScroll(){
    if(this.isScrollEnd){
      return;
    }
    if((window.innerHeight + window.scrollY) + this.scrollEndMargin >= document.body.offsetHeight){
      this.isScrollEnd = true
      this.$emit("onscrollend")
    }
  }
  
  created(){
    this.nowLoading =this.initialLoad;
    if(this.enableOnScrollEnd){
      document.addEventListener('scroll', this.onScroll)
      //画面更新後に判定を行う
      this.$nextTick(()=>{
        this.onScroll()
      })
    }
  }

  beforeUnmount(){
    if(this.enableOnScrollEnd){
      document.removeEventListener('scroll', this.onScroll)
    }
  }
}

