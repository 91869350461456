
import RootVue from '@/RootVue.vue';
import { Vue, Options } from 'vue-class-component';
import CustomerRes from '@/ts/interface/CustomerRes';
import SelectNoshigami from '@/components/parts/SelectNoshigami.vue'
import { deepCopy, formatDateToInput } from '@/ts/util';
import OrderHistoryReq from '@/ts/interface/OrderHistoryReq';
import OrderHistoryRes from '@/ts/interface/OrderHistoryRes';
import api from '@/ts/ajax/api';
import CustomerTableRes from '@/ts/interface/CustomerTableRes';
import OrderDestinationHistory from '@/ts/interface/OrderDestinationHistory';
import Loading from './Loading.vue';
import { Ref } from 'vue-property-decorator';

@Options({
  props: {
    isInsert:{
      type: Boolean,
      required: true
    },
    order:{
      type: Object,
      // required: true
    },
  },
  emits: ['save-order'],
  components: {
    SelectNoshigami,
    Loading
  }
})
export default class extends Vue {
  // @ts-expect-error
  $root: RootVue;
  //mounted前のlifesycleだと初期化前で使用できない点に注意
  @Ref() readonly loading = null as any as Loading;
  
  order!: OrderHistoryRes;
  isInsert!:Boolean;
  customerNotFound = false
  newOrder = {} as OrderHistoryReq & OrderHistoryRes;

  searchCustomer(){
    this.newOrder.customerName = "";
    this.newOrder.customerKana = "";
    if(!this.newOrder.customerId){
      this.customerNotFound = false
      return
    }

    this.loading?.start()
    const promiseList = [api.getCustomerTable({id:this.newOrder.customerId}), api.getCustomerDestination(this.newOrder.customerId)]
    promiseList[0].then((res:Response) => {
      if(res.ok){
        res.json().then((customerTable:CustomerTableRes) => {
          if(customerTable.list[0]){
            this.customerNotFound = false
            this.newOrder.customerName = customerTable.list[0].name;
            this.newOrder.customerKana = customerTable.list[0].kana;
          }else{
            this.customerNotFound = true
          }
        })
      }
    })
    
    promiseList[1].then((res:Response)=>{
      if(res.ok){
        res.json().then((customerDest: CustomerRes[]) => {
          this.newOrder.destination = this.customerDestToOrderDest(customerDest)
        })
      }
    })

    Promise.all(promiseList).then(() => this.loading?.finished())
    
  }

  showDialog(){
    this.$root.dialogs.customerSearch.openDialog(this.newOrder.customerId).then((customer:CustomerRes|null)=>{
      if(customer){
        this.loading?.start()
        api.getCustomerDestination(customer.id).then((res:Response)=>{
          if(res.ok){
            res.json().then((customerDest: CustomerRes[])=>{
              this.loading?.finished()
              this.customerNotFound = false
              this.newOrder.customerId = customer.id;
              this.newOrder.customerName = customer.name;
              this.newOrder.customerKana = customer.kana;

              this.newOrder.destination = this.customerDestToOrderDest(customerDest)
            })
          }
        })
      }
    })
  }

  customerDestToOrderDest(customerDest: CustomerRes[]){
    return customerDest.map((custmDest)=>{
      return {
        customerId: custmDest.id,
        customerName: custmDest.name,
        customerKana: custmDest.kana,
        customerZipCode: custmDest.zipCode,
        customerPhoneNo: custmDest.phoneNo,
        } as OrderDestinationHistory
    })
  }

  save(){
    this.$emit("save-order", this.newOrder)
  }


  async created(){
    this.newOrder = deepCopy(this.order)as any
    if(!this.isInsert){
      this.newOrder.orderDate = formatDateToInput(this.newOrder.orderDate)
      
      const res:Response = await api.getOrderDestination(this.order.id)
      if(res.ok){
        const data:OrderDestinationHistory[] = await res.json()
        this.newOrder.destination = data
        this.newOrder.destination.forEach((dest) => {
          dest.sendDate = formatDateToInput(dest.sendDate)
        })
      }
    }
  }
  
}
