import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialog_customer_search = _resolveComponent("dialog-customer-search")!
  const _component_dialog_confirm = _resolveComponent("dialog-confirm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_dialog_customer_search, { ref: "customerSearch" }, null, 512),
    _createVNode(_component_dialog_confirm, { ref: "confirm" }, null, 512)
  ], 64))
}