import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/loading.png'


const _withScopeId = n => (_pushScopeId("data-v-5be5b826"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"text-align":"center","padding":"6px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle([{"vertical-align":"middle"}, {'min-height':_ctx.minHeight, 'min-width':_ctx.minWidth, 'display': _ctx.display}])
  }, [
    (!_ctx.nowLoading || _ctx.showContentsOnLoading)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "loading-fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            class: "now-loading",
            style: _normalizeStyle({'height':_ctx.imgHeight, 'width':_ctx.imgWidth}),
            src: _imports_0
          }, null, 4)
        ], 512), [
          [_vShow, _ctx.nowLoading]
        ])
      ]),
      _: 1
    })
  ], 4))
}