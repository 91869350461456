
import { Options, Vue } from 'vue-class-component';
import RootVue from '@/RootVue.vue'
import MasterComponent from '@/components/MasterComponent.vue'
import StoreMaster from '@/ts/interface/StoreMaster';
import { Ref } from 'vue-property-decorator';
import api from '@/ts/ajax/api';
import Loading from '@/components/Loading.vue';

@Options({
  components: {
    MasterComponent,
    Loading
  }
})
export default class extends Vue {
  //処理に影響を与えないコード。$rootに関するコード補完機能を有効化するためだけに定義。値は設定しない。
  // @ts-expect-error
  $root:RootVue;
  //mounted前のlifesycleだと初期化前で使用できない点に注意
  @Ref() readonly loading = null as any as Loading;

  rows = [] as StoreMaster[]

  save(method: "post" | "put" | "delete", item:StoreMaster){
    this.loading?.start(true)
    api.saveStore(method, item).then((resp)=>{
      if(resp.ok){
        this.load(true)
      }
    })
  }

  load(showContentsOnLoading:boolean){
    this.loading?.start(showContentsOnLoading)
    api.getStore().then((resp:Response)=>{
      if(resp.ok){
        resp.json().then((data:StoreMaster[]) => {
          this.rows = data
          this.loading?.finished()
        })
      }
    })
  }

  created() {
    this.load(false)
  }




}  

