import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_master_component = _resolveComponent("master-component")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createBlock(_component_loading, { ref: "loading" }, {
    default: _withCtx(() => [
      _createVNode(_component_master_component, {
        masterName: "のし紙",
        master: _ctx.rows,
        onItemCreate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.save('post', $event))),
        onItemUpdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.save('put', $event))),
        onItemDelete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.save('delete', $event)))
      }, null, 8, ["master"])
    ]),
    _: 1
  }, 512))
}