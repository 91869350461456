import { reactive } from 'vue'
import LoginRes from '../interface/LoginRes'
import Nortification from './nortification'
import EditingLock from './editingLock'


class GlobalState{
    private loginInfo = {} as LoginRes
    public getLoginInfo(){
        return this.loginInfo;
    }
    public setLoginInfo(loginInfo: LoginRes){
        this.nortification.clear("rest-error")
        this.loginInfo = loginInfo
    }

    public nortification = new Nortification()
    public editingLock = new EditingLock()
    

    public isLogined(){
        return !!this.loginInfo.csrftoken
    }
}


//全ファイルで共通して変数を使うので、newしてreactive化（HTML反映化）して使用する
export default reactive(new GlobalState())
