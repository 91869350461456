

import { Options, Vue } from 'vue-class-component';
import RootVue from '@/RootVue.vue'
import ActionHistoryEdit from '@/components/ActionHistoryEdit.vue'
import SelectPrefecture from '@/components/parts/SelectPrefecture.vue'
import SelectStore from '@/components/parts/SelectStore.vue'
import SelectAction from '@/components/parts/SelectAction.vue'
import ActionHistoryRes from "@/ts/interface/ActionHistoryRes";
import ActionHistoryTableRes from "@/ts/interface/ActionHistoryTableRes";
import SearchReq from "@/ts/interface/SearchReq";
import Pager from "@/components/parts/Pager.vue";
import { Ref, Watch } from 'vue-property-decorator';
import { PAGE_SIZE } from '@/ts/const';
import Loading from '@/components/Loading.vue';
import api from '@/ts/ajax/api';
import { removeEmptyProperties } from '@/ts/util';
import ActionHistoryReq from '@/ts/interface/ActionHistoryReq';
import globalState from '@/ts/state/globalState';


@Options({
  components: {
    ActionHistoryEdit,
    SelectPrefecture,
    SelectStore,
    SelectAction,
    Pager,
    Loading
  }
})
export default class extends Vue {
  //処理に影響を与えないコード。$rootに関するコード補完機能を有効化するためだけに定義。値は設定しない。
  // @ts-expect-error
  $root:RootVue;
  //mounted前のlifesycleだと初期化前で使用できない点に注意
  @Ref() readonly loading = null as any as Loading;

  isSearchMode = true
  isFirstLoad = true;
  searchReq = {} as SearchReq

  actionTable = {list:[]} as any as ActionHistoryTableRes
  
  isEditInsert = false
  editTarget:ActionHistoryRes | null = null

  toHankakuCode(){
    this.searchReq.id = this.$root.util.hankaku2Zenkaku(this.searchReq.id)
  }
  toHankanaWhole(){
    this.searchReq.name = this.$root.util.toHankanaWhole(this.searchReq.name)
  }
  toHankakuPhone(){
    this.searchReq.phoneNo = this.$root.util.hankaku2Zenkaku(this.searchReq.phoneNo)
  }
  search(clearTableOrder=true, clearIndex=true){
    //enterでsearchを起動した場合、changeに登録した全角->半角変換処理より早くsearchが動作するため変換を先に行う
    this.toHankakuCode()
    this.toHankanaWhole()
    this.toHankakuPhone()
    this.isFirstLoad = false
    if(clearTableOrder) this.searchReq.order = undefined
    if(clearIndex) this.searchReq.startIndex = 0
    //URLを変更して画面全体を再作成する（onUrlChangeが呼び出される）。URLが変わらない場合onUrlChangeが呼ばれないので注意
    this.$router.push({"query":removeEmptyProperties({...this.searchReq})});
  }

  insert(){
    this.isEditInsert = true
    this.showEdit({} as ActionHistoryRes)
  }

  edit(action:ActionHistoryRes){
    this.isEditInsert = false
    this.showEdit(action)
  }

  showEdit(action:ActionHistoryRes){
    this.editTarget = action
    this.isSearchMode = false
    //バック時に一覧に戻るようにヒストリーを追加。（バックボタン押下 => URL変更と判定される => @Watch("$route"..が実行される => loadし直しで一覧に戻る）
    history.pushState({}, this.$route.meta.title as any)
  }


  remove(action:ActionHistoryReq){
    this.$root.dialogs.confirm.openDialog("削除します。よろしいですか？", "削除する").then((result) => {
      if(result){
        this.loading?.start()
        api.saveActionHistory("delete", action).then((resp:Response) => {
          if(resp.ok){
            this.isFirstLoad = false
            this.load()
          }
        })
      }
    })
  }

  save(action:ActionHistoryReq){
    this.loading?.start()
    globalState.nortification.clear("saveActionHistory")
    api.saveActionHistory(this.isEditInsert ? "post" : "put", action).then((resp:Response)=>{
      if(resp.ok){  
        this.isFirstLoad = false
        this.load();   
        this.isSearchMode = true
        if(this.isEditInsert){
          // 新規追加の時は検索条件をクリアする（URL遷移する）
          this.$router.push({"query":{}});
        }
      }else{
        this.loading?.finished()
        globalState.nortification.add({identifer:"saveActionHistory", type:"error", message:"保存に失敗しました"})
      }
    })
  }

  getOrderString(culumnName:string){
    return this.searchReq.order === culumnName ? "▲" : this.searchReq.order === ("-" + culumnName) ? "▼" : ""
  }

  order(culumnName:string){
    if(this.searchReq.order?.endsWith(culumnName)){
      if(this.searchReq.order === culumnName){
        this.searchReq.order = "-" + culumnName  
      }else{
        this.searchReq.order = culumnName  
      }
    }else{
      this.searchReq.order = culumnName
    }
    //URLを変更して画面全体を再作成する（onUrlChangeが呼び出される）。URLが変わらない場合onUrlChangeが呼ばれないので注意
    this.$router.push({"query":removeEmptyProperties({...this.searchReq})});
  }

  changePage(pageIndex:number) {
    this.searchReq.startIndex = pageIndex * this.searchReq.length!
    this.search(false, false)
  }

  load(){
    this.loading?.start()
    this.isSearchMode = true
      api.getActionHistoryTable(window.location.search).then((resp:Response)=>{
        if(resp.ok){
          resp.json().then((data:ActionHistoryTableRes) => {
            this.actionTable = data
            this.loading?.finished()
          })
        }
      })
  }

  //URLが変わったタイミングで処理を行う（createのタイミングでも呼び出される。createdだと、同一画面を表示中に、URLだけを変更した場合を検知できない）
  @Watch("$route", { immediate: true, deep: false })
  onUrlChange() {
    //route.queryをそのまま代入すると、連続してページ遷移できない問題があるので別オブジェクトにコピーする
    this.searchReq = {...this.$route.query} as any
    
    if(!this.searchReq.startIndex) this.searchReq.startIndex = 0
    if(!this.searchReq.length) this.searchReq.length = PAGE_SIZE

    // this.isFirstLoad = true
    this.load()
  }
}  

