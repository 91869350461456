
import RootVue from '@/RootVue.vue';
import api from '@/ts/ajax/api';
import ActionMaster from '@/ts/interface/ActionMaster';
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    action:{
      type: [Number, String],
    },
  },
  components: {
  }
})
export default class extends Vue {
  // @ts-expect-error
  $root: RootVue;

  action!: String;
  list = [] as ActionMaster[];
  isLoaded = false

  onChange($event:any){
    this.$emit('update:action', $event.target.value)
  }
  created(){
    api.getAction().then((res:Response)=>{
      if(res.ok){
        res.json().then((data:ActionMaster[])=>{
          this.list = data
          this.isLoaded = true
        })
      }
    })
  }
}
