
import RootVue from '@/RootVue.vue';
import { Vue, Options } from 'vue-class-component';
import CustomerRes from '@/ts/interface/CustomerRes';
import SelectPrefecture from '@/components/parts/SelectPrefecture.vue'
import SelectStore from '@/components/parts/SelectStore.vue'
import { deepCopy, formatDateTimeToInput } from '@/ts/util';
import CustomerReq from '@/ts/interface/CustomerReq';
import AddressRes from '@/ts/interface/AddressRes';
import convertKana from '@/ts/convertKana';
import api from '@/ts/ajax/api';
import globalState from '@/ts/state/globalState';

@Options({
  props: {
    customer:{
      type: Object,
      // required: true
    },
  },
  emits: ['save-customer'],
  components: {
    SelectPrefecture,
    SelectStore
  }
})
export default class extends Vue {
  // @ts-expect-error
  $root: RootVue;
  customer!: CustomerRes; //親から渡されたオブジェクト
  editCustomer = {} as CustomerReq & CustomerRes; //編集用のオブジェクト

  zipNotFound = false

  skipKeyDown = false //Ctrlキー押下時など、キーDown操作をスキップする
  stopBuffer = false //押下キーからカナを割り出せないキーが押されたらストップする
  keyBuffer = "" //逐次変換するとnから始まる言葉がうまく変換されないため、押下キーをバッファする

  keyUp($envent:any){
    switch($envent.code){
      case "ControlLeft":
      case "ControlRight":
        this.skipKeyDown = false
        break;
    }
  }

  keyDown($envent:any){
    //顧客名が空であった場合、キーのバッファー処理を再開する
    if(!$envent.target.value){
      this.stopBuffer = false
      this.keyBuffer = ""
    }
    switch($envent.code){
      case "ControlLeft":
      case "ControlRight":
        this.skipKeyDown = true
        break;

      //不確実キー押されたらそこで停止する
      case "Backspace":
        this.stopBuffer = true
        if(this.keyBuffer){
          this.keyBuffer = this.keyBuffer.substr(0, this.keyBuffer.length - 1)
        }
        break;
      case "Home":
      case "ArrowLeft":
      case "ArrowUp":
        this.stopBuffer = true
        break;
      case "Tab":
        this.stopBuffer = true
        break;
      case "KeyA":
        if(this.skipKeyDown){//Ctrl + A(全体選択)を押した場合
          this.stopBuffer = true
        }
        break;
    }
    if(this.skipKeyDown || this.stopBuffer){
      return;
    }

    switch($envent.code){
      case "Space":
        this.keyBuffer += " "
        break;
      default:
        if($envent.code.startsWith("Key")){
          this.keyBuffer += $envent.code.charAt($envent.code.length - 1).toLowerCase()
        }
    }
  }

  onNameClick(){
    this.stopBuffer = true
  }

  writeKana(){
    if(this.editCustomer.name && this.keyBuffer){
      this.editCustomer.kana = this.$root.util.toHankana(convertKana(this.keyBuffer.trim()))
    }
    this.skipKeyDown = false
    this.stopBuffer = !!this.editCustomer.kana //カナ名が入っている場合はBufferにキーを入れない状態にする
    this.keyBuffer = ""
  }

  hiraToKana(){
    this.editCustomer.kana = this.$root.util.toHankana(this.editCustomer.kana)
  }

  writeZip(){
    this.editCustomer.zipCode = this.$root.util.hankaku2Zenkaku(this.editCustomer.zipCode).trim()
    const searchStr = this.editCustomer.zipCode.replace(/[-ー➖]/g, "")
    this.zipNotFound = false
    if(!searchStr) return
    api.getAddress(searchStr).then((res:Response)=>{
      if(res.ok){
        res.json().then((data:AddressRes) => {
          const findSpace = new RegExp("[\\s　]", "g");
          this.editCustomer.prefecture = data.prefecture?.replace(findSpace, "")
          this.editCustomer.city = data.city?.replace(findSpace, "")
          this.editCustomer.street = data.street?.replace(findSpace, "")
        })
      }else if(res.status === 404){
        this.zipNotFound = true
      }
    })
  }

  toHankakuPhone(){
    this.editCustomer.phoneNo = this.$root.util.hankaku2Zenkaku(this.editCustomer.phoneNo)
  }

  save(){
    globalState.editingLock.leave()
    this.editCustomer.editingUser = ""
    this.editCustomer.printDate = this.editCustomer.printDate ? this.editCustomer.printDate : null as any  //日付型なのでデータがない場合はnullを送る
    this.$emit("save-customer", this.editCustomer)
  }

  created(){
    // 親からpropで渡された値は、子で直接編集できないのでコピーする
    this.editCustomer = deepCopy(this.customer) as CustomerReq & CustomerRes
    this.editCustomer.store = this.customer.storeId
    this.editCustomer.printDate = formatDateTimeToInput(this.editCustomer.printDate)
    this.$root.focus("focusOnEdit")

    if(this.customer.editingUserId){
      globalState.editingLock.showLockMsg()
    }else if(this.customer.id){
      globalState.editingLock.enter(this.customer.id)
    }
  }
  
}
