

import { Options, Vue } from 'vue-class-component';
import RootVue from '@/RootVue.vue'
import api from '@/ts/ajax/api';
import LoginReq from '@/ts/interface/LoginReq';
import LoginRes from '@/ts/interface/LoginRes';
import globalState from '@/ts/state/globalState';


@Options({
  components: {
  }
})
export default class extends Vue {
  //処理に影響を与えないコード。$rootに関するコード補完機能を有効化するためだけに定義。値は設定しない。
  // @ts-expect-error
  $root:RootVue;
  loginReq = {
    rememberMe:true
  } as LoginReq;

  failedToLogin = false

  get invalidClass(){
    return this.failedToLogin ? 'is-invalid' : ''
  }
  login(){
    this.failedToLogin = false
    api.postLogin(this.loginReq).then((resp:Response) => {
      if(resp.ok){
        resp.json().then((data:LoginRes)=>{
          globalState.setLoginInfo(data)
          this.$router.push("/menu");
        })
      } else {
          this.failedToLogin = true
      }
    })
  }
  created () {
  }
}  

