
import RootVue from '@/RootVue.vue';
import { Vue, Options } from 'vue-class-component';
import ActionHistoryRes from "@/ts/interface/ActionHistoryRes";
import ActionHistoryReq from "@/ts/interface/ActionHistoryReq";
import CustomerRes from '@/ts/interface/CustomerRes';
import SelectAction from '@/components/parts/SelectAction.vue'
import { deepCopy, formatDateTimeToInput } from '@/ts/util';
import api from '@/ts/ajax/api';
import CustomerTableRes from '@/ts/interface/CustomerTableRes';

@Options({
  props: {
    actionHistory:{
      type: Object,
      // required: true
    },
  },
  emits: ['save-action-history'],
  components: {
    SelectAction
  }
})
export default class extends Vue {
  // @ts-expect-error
  $root: RootVue;
  actionHistory!: ActionHistoryRes;
  customerNotFound = false
  newHistory = {} as ActionHistoryReq & ActionHistoryRes;

  searchCustomer(){
    this.newHistory.customerName = "";
    this.newHistory.customerKana = "";
    if(!this.newHistory.customerId){
      this.customerNotFound = false
      return
    }
    api.getCustomerTable({id:this.newHistory.customerId}).then((res:Response) => {
      if(res.ok){
        res.json().then((customerTable:CustomerTableRes) => {
          if(customerTable.list[0]){
            this.customerNotFound = false
            this.newHistory.customerName = customerTable.list[0].name;
            this.newHistory.customerKana = customerTable.list[0].kana;
          }else{
            this.customerNotFound = true
          }
        })
      }
    })
  }
  
  showDialog(){
    this.$root.dialogs.customerSearch.openDialog(this.newHistory.customerId).then((customer:CustomerRes|null)=>{
      if(customer){
        this.customerNotFound = false
        this.newHistory.customerId = customer.id;
        this.newHistory.customerName = customer.name;
        this.newHistory.customerKana = customer.kana;
        this.newHistory.customerPhoneNo = customer.phoneNo;
      }
    })
  }

  save(){
    this.newHistory.customer = this.newHistory.customerId
    this.newHistory.action = this.newHistory.actionId
    this.newHistory.date = this.newHistory.date ? this.newHistory.date : null as any //日付型なのでデータがない場合はnullを送る
    this.$emit("save-action-history", this.newHistory)
  }

  created(){
    this.newHistory = deepCopy(this.actionHistory) as any
    this.newHistory.date = formatDateTimeToInput(this.newHistory.date)
    // console.log("this.actionHistory", this.actionHistory)
    // console.log("this.newHistory", this.newHistory)
  }
  
}
