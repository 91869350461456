
import RootVue from '@/RootVue.vue';
import { Vue, Options } from 'vue-class-component';

@Options({
  emits: ['click-background'],
  components: {
  }
})
export default class extends Vue {
  // @ts-expect-error
  $root: RootVue;
  onBGClick(event:any){
    this.$emit("click-background", event)
  }
}
