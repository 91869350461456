
import RootVue from '@/RootVue.vue';
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    prefecture:{
      type: String,
      // required: true
    },
  },
  components: {
  }
})
export default class extends Vue {
  // @ts-expect-error
  $root: RootVue;

  prefecture!: String;
  onInput($event:any){
    this.$emit('update:prefecture', $event.target.value)
  }
}
