import HeaderNortification from "../interface/HeaderNortification";

export default class {
    private nortifications = [] as HeaderNortification[];
    public get list(){
        return this.nortifications
    }
    public add(nortification:HeaderNortification){
        const idx = this.nortifications.findIndex(n => n.identifer === nortification.identifer)
        if(idx >= 0){
            this.nortifications[idx] = nortification
        }else{
            this.nortifications.push(nortification)
        }
    }
    public clear(identifer:string){
        const idx = this.nortifications.findIndex(n => n.identifer === identifer)
        if(idx >= 0){
            this.nortifications.splice(idx, 1)
        }
    }
}