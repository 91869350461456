import Address from '@/ts/ajax/address';
import Rest from './rest';
import LoginReq from '../interface/LoginReq';
import ActionMaster from '../interface/ActionMaster';
import StoreMaster from '../interface/StoreMaster';
import NoshigamiMaster from '../interface/NoshigamiMaster';
import CustomerReq, { PostPrintUketamawarishoBody } from '../interface/CustomerReq';
import CustomerDeleteReq from '../interface/CustomerDeleteReq';
import YamatoReq from '../interface/YamatoReq';
import ActionHistoryReq from '../interface/ActionHistoryReq';
import { createQueryString } from '../util';
import SearchReq from '../interface/SearchReq';
import OrderHistoryRes from '../interface/OrderHistoryRes';
import OrderHistoryReq from '../interface/OrderHistoryReq';
import CustomerRes from '../interface/CustomerRes';

// APIを叩く処理を集めたクラス
export class API {
  private address: Address;
  private rest: Rest;

  constructor(host: string) {
    this.address = new Address(host);
    this.rest = new Rest();
  }

  postLogin = (login: LoginReq): Promise<Response> => {
    return this.rest.post(this.address.loginPath(), undefined, login)
  }
  getAccount = (): Promise<Response> => {
    return this.rest.get(this.address.loginAccountPath())
  }
  getAddress = (zipCode:string|number): Promise<Response> => {
    return this.rest.get(this.address.addressPath(zipCode))
  }

  getAction = (): Promise<Response> => {
    return this.rest.get(this.address.actionPath())
  }
  saveAction = (method: "post" | "put" | "delete", item:ActionMaster): Promise<Response> => {
    return this.rest[method](this.address.actionPath(item.id), undefined, item)
  }
  
  
  getNoshigami = (): Promise<Response> => {
    return this.rest.get(this.address.noshigamiPath())
  }
  saveNoshigami = (method: "post" | "put" | "delete", item:NoshigamiMaster): Promise<Response> => {
    return this.rest[method](this.address.noshigamiPath(item.id), undefined, item)
  }

  
  getStore = (): Promise<Response> => {
    return this.rest.get(this.address.storePath())
  }
  saveStore = (method: "post" | "put" | "delete", item:StoreMaster): Promise<Response> => {
    return this.rest[method](this.address.storePath(item.id), undefined, item)
  }
  
  
  getCustomerTable = (searchReq:SearchReq): Promise<Response> => {
    return this.rest.get(this.address.customerTablePath(), createQueryString(searchReq))
  }
  saveCustomer = (method: "post" | "put", item:CustomerReq): Promise<Response> => {
    return this.rest[method](this.address.customerPath(item.id), undefined, item)
  }
  
  deleteCustomerBySearch = (searchReq:SearchReq): Promise<Response> => {
    return this.rest.delete(this.address.customerDeletePath(), createQueryString(searchReq))
  }
  deleteCustomer = (body:CustomerDeleteReq[]): Promise<Response> => {
    return this.rest.delete(this.address.customerDeletePath(), undefined, body)
  }

  getCustomerDestination = (customerId:string|number): Promise<Response> => {
    return this.rest.get(this.address.customerDestinationPath(customerId))
  }
  postCustomerDestination = (customerId:string|number, destId:string|number): Promise<Response> => {
    return this.rest.post(this.address.customerDestinationPath(customerId, destId))
  }

  getCustomerCSV = (): Promise<Response> => {
    return this.rest.get(this.address.getCustomerCSVPath())
  }
  postCustomerCSV = (file:any, forceUpload:boolean): Promise<Response> => {
    return this.rest.post(this.address.postCustomerCSVPath(forceUpload), undefined, file, false)
  }
  
  setCustomerEditing = (customerId:number): Promise<Response> => {
    return this.rest.post(this.address.customerEditingPath(customerId))
  }
  clearCustomerEditing = (customerId:number): Promise<Response> => {
    return this.rest.delete(this.address.customerEditingPath(customerId))
  }
  printUketamawarishoBySearch = (searchReq:SearchReq): Promise<Response> => {
    return this.rest.post(this.address.uketamawarishoPath(), createQueryString(searchReq))
  }
  printUketamawarisho = (body:PostPrintUketamawarishoBody[],searchReq:SearchReq): Promise<Response> => {
    return this.rest.post(this.address.uketamawarishoPath(), createQueryString(searchReq), body)
  }
  printLabelBySearch = (searchReq:SearchReq): Promise<Response> => {
    return this.rest.post(this.address.labelPath(), createQueryString(searchReq))
  }
  printLabel = (customers:CustomerRes[], searchReq:SearchReq): Promise<Response> => {
    return this.rest.post(this.address.labelPath(), createQueryString(searchReq), customers.map(customer=>{return {id:customer.id}}))
  }

  printYamatoCSVBySearch = (searchReq:SearchReq, body:YamatoReq): Promise<Response> => {
    return this.rest.post(this.address.yamatoCSVPath(), createQueryString(searchReq), body)
  }
  printYamatoCSV = (searchReq:SearchReq, body:YamatoReq): Promise<Response> => {
    return this.rest.post(this.address.yamatoCSVPath(), createQueryString(searchReq), body)
  }


  getActionHistoryTable = (querystring:string): Promise<Response> => {
    return this.rest.get(this.address.actionHistoryTablePath(), createQueryString(querystring))
  }
  saveActionHistory = (method: "post" | "put" | "delete", item:ActionHistoryReq): Promise<Response> => {
    return this.rest[method](this.address.actionHistoryPath(item.id), undefined, item)
  }

  getOrderHistoryTable = (querystring:string): Promise<Response> => {
    return this.rest.get(this.address.orderHistoryTablePath(), createQueryString(querystring))
  }
  saveOrderHistory = (method:"post"|"put",item:OrderHistoryReq): Promise<Response> => {
    if(method === "post"){
      return this.rest.post(this.address.postOrderHistoryPath(), undefined, item)
    }else{
      return this.rest.put(this.address.putOrderHistoryPath(item.id), undefined, item)
    }
  }
  deleteOrderHistory = (item:OrderHistoryReq): Promise<Response> => {
    return this.rest.delete(this.address.deleteOrderHistoryPath(item.id), undefined, item)
  }

  getOrderDestination =  (orderId:string|number): Promise<Response> => {
    return this.rest.get(this.address.orderDestinationPath(orderId))
  }
  

}

export default new API(process.env.VUE_APP_API_ORIGIN as string);

