

import RootVue from "@/RootVue.vue";
import { Vue, Options } from "vue-class-component";

@Options({
  props: {
    modelValue: {
    },
  },
  emits: ['update:modelValue'],
})
export default class extends Vue {
  // @ts-expect-error
  $root: RootVue;
  
  updateValue(event:any) {
    this.$emit('update:modelValue', event.target.value)
  }
}
