
import { Vue, Options } from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import Dialogs from '@/components/dialogs/Dialogs.vue';
import * as util from '@/ts/util';
import globalState from '@/ts/state/globalState';

@Options({
  components: {
    Dialogs
  }
})
export default class extends Vue {

  //mounted前のlifesycleだと初期化前で使用できない点に注意
  @Ref() readonly dialogs = null as any as Dialogs;

  get showBackLink() {
    return !this.$route.meta.noBackLink
  }

  get util(){
    return util;
  }

  get globalState(){
    return globalState;
  }
  
  // get messages() {
  //   return window.messages
  // }
  
  focus(elementId:string){
    this.$nextTick(()=>{
      document.getElementById(elementId)?.focus()
    })
  }

  created(){
    
  }
}
