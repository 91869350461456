
import RootVue from '@/RootVue.vue';
import { Vue, Options } from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import DialogCustomerSearch from './DialogCustomerSearch.vue';
import DialogConfirm from './DialogConfirm.vue';


@Options({
  components: {
    DialogCustomerSearch,
    DialogConfirm
  }
})
export default class extends Vue {
  // @ts-expect-error
  $root: RootVue;

  //mounted前のlifesycleだと初期化前で使用できない点に注意
  @Ref() readonly customerSearch = null as any as DialogCustomerSearch;
  @Ref() readonly confirm = null as any as DialogConfirm;

}
