

import { Options, Vue } from 'vue-class-component';
import RootVue from '@/RootVue.vue'
import CustomerEdit from '@/components/CustomerEdit.vue'
import SelectPrefecture from '@/components/parts/SelectPrefecture.vue'
import SelectStore from '@/components/parts/SelectStore.vue'
import CustomerRes from "@/ts/interface/CustomerRes";
import CustomerReq, { PostPrintUketamawarishoBody } from "@/ts/interface/CustomerReq";
import CustomerDeleteReq from "@/ts/interface/CustomerDeleteReq";
import SearchReq from "@/ts/interface/SearchReq";
import ErrorRes from "@/ts/interface/ErrorRes";
import Pager from "@/components/parts/Pager.vue";
import api from '@/ts/ajax/api';
import { Ref, Watch } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';
import { download, getNowString, getTodayString, removeEmptyProperties } from '@/ts/util';
import CustomerTableRes from '@/ts/interface/CustomerTableRes';
import {CUSTOMER_PAGE_SIZE, MAX_OPERATION_CUSTOMER_SIZE} from '@/ts/const';
import globalState from '@/ts/state/globalState';
import { YamatoCustomerReq } from '@/ts/interface/YamatoReq';

@Options({
  components: {
    CustomerEdit,
    SelectPrefecture,
    SelectStore,
    Pager,
    Loading
  }
})
export default class extends Vue {
  //処理に影響を与えないコード。$rootに関するコード補完機能を有効化するためだけに定義。値は設定しない。
  // @ts-expect-error
  $root:RootVue;
  //mounted前のlifesycleだと初期化前で使用できない点に注意
  @Ref() readonly loading = null as any as Loading;
  @Ref() readonly buttonLoading = null as any as Loading;

  checkAll = false
  isSearchMode = true
  searchReq = {} as SearchReq
  
  shipmentDate:string = ""
  productName:string = ""

  customerTable = {list:[]} as any as CustomerTableRes

  isEditInsert = false
  editTarget:CustomerRes | null = null

  get checkedCustomer():CustomerRes[]{
    //注文元、もしくは、送信先にチェックが付与された顧客一覧を取得する
    return this.customerTable.list.filter(c => c.check || c.destination?.filter(d=> d.check).length)
  }

  get hasNoCheck(){
    return !this.checkedCustomer.length
  }

  toHankakuCode(){
    this.searchReq.id = this.$root.util.hankaku2Zenkaku(this.searchReq.id)
  }
  toHankanaWhole(){
    this.searchReq.name = this.$root.util.toHankanaWhole(this.searchReq.name)
  }
  toHankakuPhone(){
    this.searchReq.phoneNo = this.$root.util.hankaku2Zenkaku(this.searchReq.phoneNo)
  }

  search(clearStartIndex=true){
    //enterでsearchを起動した場合、changeに登録した全角->半角変換処理より早くsearchが動作するため変換を先に行う
    this.toHankakuCode()
    this.toHankanaWhole()
    this.toHankakuPhone()
    if(clearStartIndex) this.searchReq.startIndex = 0
    //URLを変更して画面全体を再作成する（onUrlChangeが呼び出される）。URLが変わらない場合onUrlChangeが呼ばれないので注意
    this.$router.push({"query":removeEmptyProperties({...this.searchReq})});
  }

  onCheckAllChange(){
    this.customerTable.list.forEach(c => {
      c.check = this.checkAll
      c.destination?.forEach(d=> {
        d.check = this.checkAll
      })
    })
  }

  outputCSV(){
    this.buttonLoading?.start()
    globalState.nortification.add({type:"info", identifer:"customer-csv-download", message:"CSVをダウンロード中です"})
    api.getCustomerCSV().then((res:Response) => {
      this.buttonLoading?.finished()
      if(res.ok){
        res.blob().then((blob:Blob) => {
          globalState.nortification.add({type:"success", identifer:"customer-csv-download", message:"CSVをダウンロードしました"})
          download(blob, 'customer' +  getTodayString() + '.csv')
        })
      }else{
        console.error(res)
        globalState.nortification.add({type:"error", identifer:"customer-csv-download",message:"CSVダウンロードに失敗しました：" + res.status})
      }
    })
  }
  
  clickFileInput(){
    globalState.nortification.clear("customer-csv")
    document.getElementById("hiddenFileInput")?.click();
  }
  registerCSV(e: any){
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;

    this.$root.dialogs.confirm.openDialog(`選んだCSVファイル${files[0].name}で顧客情報を総入れ替えします。<br />よろしいですか？`, "CSVをアップロード").then((result) => {
      if(!result) return;
      this.postCSV(files[0])    
    })
  }
  postCSV(file:File, forceUpload=false){
    this.buttonLoading?.start()
    globalState.nortification.add({type:"info", identifer:"customer-csv", message:"CSVをアップロード中です"})
    api.postCustomerCSV(file, forceUpload).then((resp:Response) => {
      this.buttonLoading?.finished()
      if(resp.ok){
        globalState.nortification.add({type:"success",identifer:"customer-csv", message:"CSVのアップロードが完了しました"})
        //csv登録ではURLが変わらないので、urlが変わった時（searchした時）と同じようにリストを再読み込みするためonUrlChangeを呼び出す
        this.onUrlChange()
      }else if(resp.status === 409){
        resp.json().then((data:ErrorRes) => {
          globalState.nortification.clear("customer-csv")
          this.$root.dialogs.confirm.openDialog("処理を続けてよいでしょうか。" + data.message, "処理を続ける").then((result) => {
            if(result){
              this.postCSV(file, true)
            }
          })
        })
      }else if(resp.status === 400){
        resp.json().then((data:ErrorRes) => {
          console.error(data)
          globalState.nortification.add({type:"error",identifer:"customer-csv", message:data.error + ": " + data.message})
        })
      }else{
        console.error(resp)
        globalState.nortification.add({type:"error",identifer:"customer-csv",message:"CSV登録に失敗しました：" + resp.status})
      }

      (document.getElementById("hiddenFileInput") as any).value = '';
    })
  }
  
  async printUketamawarisho(){
        
    if(MAX_OPERATION_CUSTOMER_SIZE < (this.checkAll ? this.customerTable.allCount : this.checkedCustomer.length)){
      var result = await this.$root.dialogs.confirm.openDialog("一度に印刷できる依頼主は最大" + MAX_OPERATION_CUSTOMER_SIZE + "件となります。", "印刷を続ける")
      if(!result) return
    }

    this.buttonLoading?.start()

    var requestPromise;
    if(this.checkAll){
      requestPromise = api.printUketamawarishoBySearch(this.searchReq)
    }else{
      var requestData = [] as PostPrintUketamawarishoBody[] 
      this.checkedCustomer.forEach((customer)=>{
        requestData.push({id: customer.id, destIdList:this.getCheckedDestinationId(customer)})
      })
      requestPromise = api.printUketamawarisho(requestData, this.searchReq)
    }
    requestPromise.then((resp:Response)=>{
      this.buttonLoading?.finished()
      if(resp.ok){
        resp.blob().then((blob:Blob) => {
          download(blob, ('uketamawarisho_' +  getNowString() + '.xlsx').replace(/\s/gi,""))
        })
      }else{
        console.error(resp)
        globalState.nortification.add({type:"error",message:"印刷に失敗しました：" + resp.status})
      }

      var noDest = resp.headers.get('kikuya-uketamawarisho-xlsx-no-dest')
      if(noDest){
        globalState.nortification.add({identifer:"printUketamawarisho", type:"warn", message:`顧客コード${noDest}には送り先がありません。`})
      }
    })
  }
  async printLabel(){
    
    if(MAX_OPERATION_CUSTOMER_SIZE < (this.checkAll ? this.customerTable.allCount : this.checkedCustomer.length)){
      var result = await this.$root.dialogs.confirm.openDialog("一度に印刷できる依頼主は最大" + MAX_OPERATION_CUSTOMER_SIZE + "件となります。", "印刷を続ける")
      if(!result) return
    }

    this.buttonLoading?.start()

    var requestPromise;
    if(this.checkAll){
      requestPromise = api.printLabelBySearch(this.searchReq)
    }else{
      requestPromise = api.printLabel(this.checkedCustomer, this.searchReq)
    }

    requestPromise.then((resp:Response)=>{
      this.buttonLoading?.finished()
      if(resp.ok){
        resp.blob().then((blob:Blob)=>{
          download(blob, ('label_' +  getNowString() + '.xlsx').replace(/\s/gi,""))
        })
      }else{
        console.error(resp)
        globalState.nortification.add({type:"error",message:"ラベル印刷に失敗しました：" + resp.status})
      }
    })
  }

  getCheckedDestinationId(customer:CustomerRes):number[]{
    if(customer.check){
      //送り先全件印刷する場合は空配列とする
      return []
    }else{
      //全件印刷しない場合は印刷するid配列を送信する
      return customer.destination!.filter(d => d.check).map(d => d.id)
    }
  }

  onYamatoCSVClick(){
    let msg = ""
    if(!this.shipmentDate){
      msg += "出荷予定日"
    }

    if(!this.productName){
      if(msg){
        msg += "と"
      }
      msg += "品名"
    }
        
    if(msg){
      this.$root.dialogs.confirm.openDialog(msg + "が入力されていませんがCSV出力しますか？", "CSVを出力").then((result) => {
        if(result){
          this.outputYamatoCSV()
        }
      })
    }else{
      this.outputYamatoCSV()
    }
  }

  async outputYamatoCSV(){

    if(MAX_OPERATION_CUSTOMER_SIZE < (this.checkAll ? this.customerTable.allCount : this.checkedCustomer.length)){
      var result = await this.$root.dialogs.confirm.openDialog("一度に出力できる依頼主は最大" + MAX_OPERATION_CUSTOMER_SIZE + "件となります。", "出力を続ける")
      if(!result) return
    }
    this.buttonLoading?.start()

    var requestPromise;
    var requestBody = {customer: this.checkAll ? [] : this.getYamatoCustomer(), shipmentDate: this.shipmentDate, productName:this.productName}
    if(this.checkAll){
      requestPromise = api.printYamatoCSVBySearch(this.searchReq, requestBody)
    }else{
      requestPromise = api.printYamatoCSV(this.searchReq, requestBody)
    }
    requestPromise.then((resp:Response)=>{
      this.buttonLoading?.finished()
      if(resp.ok){
        resp.blob().then((blob:Blob)=>{
          download(blob, ('ヤマト_' +  getNowString() + '.csv').replace(/\s/gi,""))
        })
        const info = resp.headers.get('kikuya-yamato-csv-no-dest')
        if(info){
          const noDestList = JSON.parse(info) as number[]
          if(noDestList.length){
            globalState.nortification.add({identifer:"yamato-csv-no-dest", type:"warn", message:"次の顧客には送り先がありませんでした。顧客コード:" + noDestList.join(",")})
          } 
        }
      }else{
        console.error(resp)
        globalState.nortification.add({type:"error",message:"CSV印刷に失敗しました：" + resp.status})
      }
    })
  }

  getYamatoCustomer(){
    return this.checkedCustomer.map(custm => {
      return {
        id: custm.id,
        destination: (!custm.check && custm.destination) ? custm.destination.filter(dest => dest.check).map(dest => dest.id) : []
      } as YamatoCustomerReq
    })
  }

  onCustomerCheck(customer:CustomerRes) {
    if(!customer.check){
      this.checkAll = false
    }
    customer.destination?.forEach(d => {
      d.check = customer.check
    })
  }

  onDestCheck(customer:CustomerRes, dest:CustomerRes){
    if(!dest.check){
      this.checkAll = false
      customer.check = false
    }
  }

  switchDest(customer:CustomerRes) {
    if(customer.showDestination){
      customer.showDestination = false
    }else{
      this.loadDestination(customer)
    }
  }

  loadDestination(customer:CustomerRes){
    this.loading?.start()
    api.getCustomerDestination(customer.id).then((res:Response)=>{
      if(res.ok){
        res.json().then((data:CustomerRes[])=>{
          this.loading?.finished()
          customer.destination = data
          if(data && data.length){
            customer.showDestination = true

            customer.destination.forEach((destCustomer)=>{
              destCustomer.check = customer.check
            })
          }
        })
      }
    })
  }

  insert(){
    this.isEditInsert = true
    this.showEdit({} as CustomerRes)  
  }

  edit(customer:CustomerRes, dest:CustomerRes){
    const editTarget = dest ?? customer; 
    this.isEditInsert = false
    this.showEdit(editTarget)  
  }

  showEdit(editTarget :CustomerRes){
    this.editTarget = editTarget
    this.isSearchMode = false
    //バック時に一覧に戻るようにヒストリーを追加。（バックボタン押下 => URL変更と判定される => @Watch("$route"..が実行される => loadし直しで一覧に戻る）
    history.pushState({}, this.$route.meta.title as any)
  }

  async remove(){
    var confirmPromise;
    if(MAX_OPERATION_CUSTOMER_SIZE < (this.checkAll ? this.customerTable.allCount : this.checkedCustomer.length)){
      var result = await this.$root.dialogs.confirm.openDialog("一度に削除できる依頼主は最大" + MAX_OPERATION_CUSTOMER_SIZE + "件となります。", "続ける")
      if(!result) return
    }

    if(this.checkAll){
      confirmPromise = this.$root.dialogs.confirm.openDialog("検索条件に合致する顧客" + Math.min(MAX_OPERATION_CUSTOMER_SIZE ,this.customerTable.allCount) + "件を削除します。よろしいですか？", "削除する")
    }else{
      confirmPromise = this.$root.dialogs.confirm.openDialog("選択された顧客を削除します。よろしいですか？", "削除する")
    }

    confirmPromise.then((result) => {
      if(result){
        this.buttonLoading?.start()

        var requestPromise;
        if(this.checkAll){
          requestPromise = api.deleteCustomerBySearch(this.searchReq)
        }else{
          requestPromise = api.deleteCustomer(this.createDeleteBody())
        }

        requestPromise.then((res:Response) =>{
          this.buttonLoading?.finished()
          if(res.ok){
            this.load()
          }else{
            res.json().then((data:ErrorRes) => {
              globalState.nortification.add({type:"error",message:data.error + data.message})
            })
          }
        })
      }
    })
  }

  createDeleteBody(){
    const body = [] as CustomerDeleteReq[]
    this.customerTable.list.forEach(c => {
      if(c.check){
        body.push({ id: c.id.toString(), destinationIds:[] })
      }else if(c.destination){
        const destinationIds = c.destination.filter(d => d.check).map(d => d.id)
        if(destinationIds.length){
          body.push({ id: c.id.toString(), destinationIds })
        }
      }
    })
    return body
  }


  addDestination(customer:CustomerRes){
    this.$root.dialogs.customerSearch.openDialog().then(destCustomer => {
      if(destCustomer){
        this.loading?.start()
        api.postCustomerDestination(customer.id, destCustomer.id).then((resp:Response) => {
          if(resp.ok){
            this.loadDestination(customer)
          }
        })
      }
    })
  }

  back(){
    this.backToSearchMode()
    globalState.editingLock.leave()
  }

  backToSearchMode(){
    this.isSearchMode = true
    window.scrollTo(0, 0);
    this.loading.enableNextScrollEnd()
  }
  
  getOrderString(culumnName:string){
    return this.searchReq.order === culumnName ? "▲" : this.searchReq.order === ("-" + culumnName) ? "▼" : ""
  }
  orderByName(){
    const culumnName = "name";
    if(this.searchReq.order === "-" + culumnName){
      this.searchReq.order = culumnName  
    }else{
      this.searchReq.order = "-" + culumnName  
    }
    //URLを変更して画面全体を再作成する（onUrlChangeが呼び出される）。URLが変わらない場合onUrlChangeが呼ばれないので注意
    this.$router.push({"query":removeEmptyProperties({...this.searchReq})});
  }

  save(customer:CustomerReq){
    this.loading?.start()
    globalState.nortification.clear("saveCustomer")
    
    api.saveCustomer(this.isEditInsert ? "post" : "put", customer).then((resp:Response)=>{
      if(resp.ok){  
        this.backToSearchMode()
        this.load();    
        if(this.isEditInsert){
          // 新規追加の時は検索条件をクリアする（URL遷移する）
          this.$router.push({"query":{}});
        }
      }else{
        this.loading?.finished()
        globalState.nortification.add({identifer:"saveCustomer", type:"error", message:"保存に失敗しました"})
      }
    })
  }

  load(isNextLoading=false){
    this.loading?.start()
    
    if(isNextLoading){
      this.searchReq.startIndex = Number(this.searchReq.startIndex ?? 0) + Number(this.searchReq.length ?? 0)
    }else{
      this.searchReq.startIndex = 0
    }
    api.getCustomerTable(this.searchReq).then((resp:Response)=>{
      if(resp.ok){
        resp.json().then((data:CustomerTableRes) => {
          if(data && data.list){
            data.list.forEach((customer)=>{
              customer.check = this.checkAll
            })
          }

          if(isNextLoading){
            this.customerTable.allCount = data.allCount
            this.customerTable.list.push(...data.list)
          }else{
            this.customerTable = data
          }
          this.loading?.finished()
          // 読込件数があった場合はスクロールEndで続きを読み込む。
          if (data.list.length == this.searchReq.length) {
            this.loading.enableNextScrollEnd()
          }
        })
      }
    })
  }

  loadNext() {
    if(this.isSearchMode){
      this.load(true)
    }
  }



  
  //URLが変わったタイミングで処理を行う（createのタイミングでも呼び出される。createdだと、同一画面を表示中に、URLだけを変更した場合を検知できない）
  @Watch("$route", { immediate: true, deep: false })
  onUrlChange() {
    globalState.editingLock.leave()
    //route.queryをそのまま代入すると、連続してページ遷移できない問題があるので別オブジェクトにコピーする
    this.searchReq = {...this.$route.query} as any
    if(!this.searchReq.startIndex) this.searchReq.startIndex = 0
    if(!this.searchReq.length) this.searchReq.length = CUSTOMER_PAGE_SIZE

    this.load()
  }
}  

