
import { Options, Vue } from "vue-class-component";
import RootVue from "@/RootVue.vue";

@Options({
  components: {
  },
})
export default class extends Vue {
  //処理に影響を与えないコード。$rootに関するコード補完機能を有効化するためだけに定義。値は設定しない。
  // @ts-expect-error
  $root: RootVue;

  created() {}
}
