import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3dd4128e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination b-pagination" }
const _hoisted_2 = ["tabindex"]
const _hoisted_3 = ["tabindex"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "page-item number" }
const _hoisted_6 = {
  type: "button",
  tabindex: "-1",
  class: "page-link center"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["tabindex"]
const _hoisted_9 = ["tabindex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", {
      class: _normalizeClass(["page-item", _ctx.beforeDisabled])
    }, [
      _createElementVNode("button", {
        type: "button",
        class: "page-link before begin",
        tabindex: _ctx.beforeTabIndex,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-page', 0)))
      }, "«", 8, _hoisted_2)
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["page-item", _ctx.beforeDisabled])
    }, [
      _createElementVNode("button", {
        type: "button",
        class: "page-link before",
        tabindex: _ctx.beforeTabIndex,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click-page', _ctx.pageIndex - 1)))
      }, "‹", 8, _hoisted_3)
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttonBeforeCount, (n) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "page-item number active",
        key: n
      }, [
        _createElementVNode("button", {
          type: "button",
          tabindex: "0",
          class: "page-link",
          onClick: ($event: any) => (_ctx.$emit('click-page',_ctx.pageIndex - _ctx.buttonBeforeCount + n - 1))
        }, _toDisplayString(_ctx.buttonBeforeStart + n), 9, _hoisted_4)
      ]))
    }), 128)),
    _createElementVNode("li", _hoisted_5, [
      _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.pageIndex + 1), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttonAfterCount, (n) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "page-item number active",
        key: _ctx.pageIndex + n
      }, [
        _createElementVNode("button", {
          type: "button",
          tabindex: "0",
          class: "page-link",
          onClick: ($event: any) => (_ctx.$emit('click-page', _ctx.pageIndex + n))
        }, _toDisplayString(_ctx.buttonAfterStart + n), 9, _hoisted_7)
      ]))
    }), 128)),
    _createElementVNode("li", {
      class: _normalizeClass(["page-item", _ctx.afterDisabled])
    }, [
      _createElementVNode("button", {
        type: "button",
        class: "page-link after",
        tabindex: _ctx.afterTabIndex,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click-page', _ctx.pageIndex + 1)))
      }, "›", 8, _hoisted_8)
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["page-item", _ctx.afterDisabled])
    }, [
      _createElementVNode("button", {
        type: "button",
        class: "page-link after end",
        tabindex: _ctx.afterTabIndex,
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('click-page', _ctx.pageLength - 1)))
      }, "»", 8, _hoisted_9)
    ], 2)
  ]))
}