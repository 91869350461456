

import { Options, Vue } from 'vue-class-component';
import RootVue from '@/RootVue.vue'
import OrderHistoryEdit from '@/components/OrderHistoryEdit.vue'
import SelectPrefecture from '@/components/parts/SelectPrefecture.vue'
import SelectStore from '@/components/parts/SelectStore.vue'
import SearchReq from "@/ts/interface/SearchReq";
import OrderHistoryTableRes from "@/ts/interface/OrderHistoryTableRes";
import Pager from "@/components/parts/Pager.vue";
import Loading from '@/components/Loading.vue';
import { Ref, Watch } from 'vue-property-decorator';
import { PAGE_SIZE } from '@/ts/const';
import api from '@/ts/ajax/api';
import { removeEmptyProperties } from '@/ts/util';
import globalState from '@/ts/state/globalState';
import OrderHistoryReq from '@/ts/interface/OrderHistoryReq';
import OrderHistoryRes from '@/ts/interface/OrderHistoryRes';
import OrderDestinationHistory from '@/ts/interface/OrderDestinationHistory';


@Options({
  components: {
    OrderHistoryEdit,
    SelectPrefecture,
    SelectStore,
    Pager,
    Loading
  }
})
export default class extends Vue {
  //処理に影響を与えないコード。$rootに関するコード補完機能を有効化するためだけに定義。値は設定しない。
  // @ts-expect-error
  $root:RootVue;
  //mounted前のlifesycleだと初期化前で使用できない点に注意
  @Ref() readonly loading = null as any as Loading;

  isSearchMode = true
  isEditInsert = false
  searchReq = {} as SearchReq
  orderTable = {list:[]} as any as OrderHistoryTableRes

  editTarget:OrderHistoryRes | null = null
  destOrder:"productCode"|"-productCode"| "sendDate" | "-sendDate" | null= null

  toHankakuCode(){
    this.searchReq.id = this.$root.util.hankaku2Zenkaku(this.searchReq.id)
  }
  toHankanaWhole(){
    this.searchReq.name = this.$root.util.toHankanaWhole(this.searchReq.name)
  }
  toHankakuPhone(){
    this.searchReq.phoneNo = this.$root.util.hankaku2Zenkaku(this.searchReq.phoneNo)
  }
  search(clearTableOrder=true, clearIndex=true){
    //enterでsearchを起動した場合、changeに登録した全角->半角変換処理より早くsearchが動作するため変換を先に行う
    this.toHankakuCode()
    this.toHankanaWhole()
    this.toHankakuPhone()
    if(clearTableOrder) this.searchReq.order = undefined
    if(clearIndex) this.searchReq.startIndex = 0
    this.destOrder = null
    //URLを変更して画面全体を再作成する（onUrlChangeが呼び出される）。URLが変わらない場合onUrlChangeが呼ばれないので注意
    this.$router.push({"query":removeEmptyProperties({...this.searchReq})});
  }

  switchDest(order:OrderHistoryRes) {
    if(order.showDestination){
      order.showDestination = false
    }else{
      this.loadDestination(order)
    }
  }

  loadDestination(order:OrderHistoryRes){
    this.loading?.start()
    api.getOrderDestination(order.id).then((res:Response)=>{
      if(res.ok){
        res.json().then((data:OrderDestinationHistory[])=>{
          this.loading?.finished()
          order.destination = data
          order.showDestination = !!(data && data.length)
          this.orderDestinations()
        })
      }
    })
  }

  insert(){
    this.isEditInsert = true
    this.showEdit({} as OrderHistoryRes)
  }

  edit(order:OrderHistoryRes){
    this.isEditInsert = false
    this.showEdit(order)
  }
  
  showEdit(order:OrderHistoryRes){
    // console.log("showEdit", order)
    this.editTarget = order
    this.isSearchMode = false
    //バック時に一覧に戻るようにヒストリーを追加。（バックボタン押下 => URL変更と判定される => @Watch("$route"..が実行される => loadし直しで一覧に戻る）
    history.pushState({}, this.$route.meta.title as any)
  }

  remove(order:OrderHistoryReq){
    this.$root.dialogs.confirm.openDialog("削除します。よろしいですか？", "削除する").then((result) => {
      if(result){
        this.loading?.start()
        api.deleteOrderHistory(order).then((resp:Response) => {
          if(resp.ok){
            this.load()
          }
        })
      }
    })
  }

  save(order:OrderHistoryReq){
    this.loading?.start()
    globalState.nortification.clear("saveOrderHistory")

    api.saveOrderHistory(this.isEditInsert ? "post" : "put", order as any).then((resp:Response)=>{
      if(resp.ok){  
        this.load();
        this.isSearchMode = true
        if(this.isEditInsert){
          // 新規追加の時は検索条件をクリアする（URL遷移する）
          this.$router.push({"query":{}});
        }
      }else{
        this.loading?.finished()
        globalState.nortification.add({identifer:"saveOrderHistory",type:"error", message:"保存に失敗しました"})
      }
    })
  }

  getOrderString(culumnName:string){
    return this.searchReq.order === culumnName ? "▲" : this.searchReq.order === ("-" + culumnName) ? "▼" : ""
  }
  order(culumnName:string){
    if(this.searchReq.order?.endsWith(culumnName)){
      if(this.searchReq.order === culumnName){
        this.searchReq.order = "-" + culumnName  
      }else{
        this.searchReq.order = culumnName  
      }
    }else{
      this.searchReq.order = culumnName
    }
    //URLを変更して画面全体を再作成する（onUrlChangeが呼び出される）。URLが変わらない場合onUrlChangeが呼ばれないので注意
    this.$router.push({"query":removeEmptyProperties({...this.searchReq})});
  }

  getOrderDestString(culumnName:string){
    return this.destOrder === culumnName ? "▲" : this.destOrder === ("-" + culumnName) ? "▼" : ""
  }
  orderDest(culumnName:string){
    if(this.destOrder?.endsWith(culumnName)){
      if(this.destOrder === culumnName){
        this.destOrder = "-" + culumnName  as any
      }else{
        this.destOrder = culumnName as any
      }
    }else{
      this.destOrder = culumnName as any
    }
    this.orderDestinations()
  }
  orderDestinations(){
    if(!this.destOrder) return
    const propName = this.destOrder.startsWith("-") ? this.destOrder.substr(1) : this.destOrder
    this.orderTable.list.forEach(history => {
      if(history.destination){
        history.destination = 
          history.destination.sort((a:any,b:any) => {
            if(this.destOrder?.startsWith("-")){
              return this.compareStr(b[propName], a[propName])
            }else{
              return this.compareStr(a[propName], b[propName])
            }
          })
      }
    })
  }
  compareStr(str1:string, str2:string){
    return str1 === str2 ? 0 : str1 > str2 ? 1 : -1        
  }
  changePage(pageIndex:number) {
    this.searchReq.startIndex = pageIndex * this.searchReq.length!
    this.search(false, false)
  }
  load(){
    this.loading?.start()
    this.isSearchMode = true
    api.getOrderHistoryTable(window.location.search).then((resp:Response)=>{
      if(resp.ok){
        resp.json().then((data:OrderHistoryTableRes) => {
          this.orderTable = data
          this.loading?.finished()
        })
      }
    })
  }
  //URLが変わったタイミングで処理を行う（createのタイミングでも呼び出される。createdだと、同一画面を表示中に、URLだけを変更した場合を検知できない）
  @Watch("$route", { immediate: true, deep: false })
  onUrlChange() {
    //route.queryをそのまま代入すると、連続してページ遷移できない問題があるので別オブジェクトにコピーする
    this.searchReq = {...this.$route.query} as any
    
    if(!this.searchReq.startIndex) this.searchReq.startIndex = 0
    if(!this.searchReq.length) this.searchReq.length = PAGE_SIZE

    this.load()
  }
}  

