
import RootVue from '@/RootVue.vue';
import api from '@/ts/ajax/api';
import NoshigamiMaster from '@/ts/interface/NoshigamiMaster';
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    noshigami:{
      type: [Number, String],
    },
  },
  components: {
  }
})
export default class extends Vue {
  // @ts-expect-error
  $root: RootVue;

  noshigami!: String;
  list = [] as NoshigamiMaster[];
  isLoaded = false

  onChange($event:any){
    this.$emit('update:noshigami', $event.target.value)
  }
  created(){
    api.getNoshigami().then((res:Response)=>{
      if(res.ok){
        res.json().then((data:NoshigamiMaster[])=>{
          this.list = data
          this.isLoaded = true
        })
      }
    })
  }
}
