

import { Options, Vue } from 'vue-class-component';
import RootVue from '@/RootVue.vue'
import MasterCommon from '@/ts/interface/MasterCommon'


@Options({
  props: {
    masterName:{
      type: String,
      // required: true
    },
    master: {
      type: Object,
      required: true
    }
  },
  components: {
  }
})
export default class extends Vue {
  //処理に影響を与えないコード。$rootに関するコード補完機能を有効化するためだけに定義。値は設定しない。
  // @ts-expect-error
  $root:RootVue;

  masterName!: String;
  master!: MasterCommon[];

  listMode = true;
  editMaster:MasterCommon|null = null;
  editText:string = "";

  showNewInput(){
    this.listMode = false
    this.editMaster = null
    this.editText = ""
    this.$root.focus("masterInput")
  }
  showEditInput(data: MasterCommon) {
    this.listMode = false
    this.editMaster = data
    this.editText = data.text
    this.$root.focus("masterInput")
  }

  remove(data: MasterCommon) {
    this.$root.dialogs.confirm.openDialog("削除します。よろしいですか？", "削除する").then((result) => {
      if(result){
        this.$emit('item-delete', data)
      }
    })
  }

  save(){
    if(!this.editText){
      return;
    }
    if(this.editMaster){
      var updateData = {...this.editMaster}
      updateData.text = this.editText
      this.$emit('item-update', updateData)
    }else{
      this.$emit('item-create', {text:this.editText})
    }
    this.listMode = true
  }
}  

